
import Vue from 'vue'
import { mapGetters } from 'vuex'
import loadLine from './../loadLine/index.vue'
import { ILevel, VerificationStatuses } from './../../../../types'

const defaultLevelData: ILevel = {
  status: VerificationStatuses.notStarted,
  reason: null
}

export default Vue.extend({
  name: 'kyc-advanced',
  data () {
    return {
      openedPanel: 1 as number | null
    }
  },
  props: {
    levelData: {
      type: Object,
      required: true,
      default: () => defaultLevelData
    }
  },
  mounted () {
    this.openedPanel = this.levelData.status === 'notStarted' ? 1 : 0
  },
  computed: {
    ...mapGetters('user', ['getUserData']),
    loadLineStatus () {
      const map = {
        extended: 'in-progress',
        advanced: 'success'
      }
      const level: keyof typeof map = this.getUserData.verificationRights.levelName
      return map[level] ?? ''
    }
  },
  methods: {
    proceedVerification () {
      this.$emit('proceedVerification')
    }
  },
  components: { loadLine }
})
