
import Vue, { PropType } from 'vue'
import { Notification } from '@whitelist/wl-old-library/store/notifications/helpers/types'
import PassportNotificationTimer from '../timer/index.vue'
import { ActivePopupTypes } from '@/store/modules/popup/types'
import { parseNotificationString } from '@/constants/notifications'

export default Vue.extend({
  name: 'passportNotification',
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      KYCLink: {
        link: '',
        loading: true,
        error: false,
        status: 'request',
        id: -1
      }
    }
  },
  methods: {
    // async getExtendedLink () {
    //   this.KYCLink.loading = true
    //   const kycData = await this.$services.authorization.user.kycData('document_face')
    //   const kycData = { success: false }
    //   if (!kycData.success) {
    //     this.KYCLink.error = true
    //     return
    //   }
    //   this.KYCLink.link = kycData.data.url
    //   this.KYCLink.status = kycData.data.status
    //   this.KYCLink.id = kycData.data.id
    //   this.KYCLink.loading = false
    // },
    handleMainAction () {
      const action = this.notification.actions.mainButton
      if (action === undefined) return

      if (action.url.includes('{local:PASSPORT_KYC_URL}')) {
        return this.$emit('proceedVerification')
      }

      window.open(this.parseNotificationString(action.url), '_blank')
    },
    continueVerify (from: string) {
      const vm = this as any
      if (this.KYCLink.status === 'process') {
        window.open(this.KYCLink.link, '_blank')
        return
      }
      this.$store.dispatch('popupModule/openPopup', {
        type: ActivePopupTypes.action,
        letDefaultClose: true,
        title: 'Подтверждение достоверности\nпредоставляемых данных',
        content: '',
        slot: {
          name: 'privacyPolicyAgreement',
          data: {
            linkData: {
              url: this.KYCLink.link,
              id: this.KYCLink.id,
              status: this.KYCLink.status
            }
          },
          on: {
            onSuccess () {
              vm.kycData.status = 'process'
              vm.$store.commit('popupModule/setClosed')
            }
          }
        }
      })
    },
    parseNotificationString (body: string) {
      return parseNotificationString(body, this.notification.vars)
    }
  },
  created () {
    // Вызов функции с новым бэкэндом вызывает ошибку. Нужно проверить для чего текущий функционал
    // this.getExtendedLink()
  },
  components: {
    PassportNotificationTimer
  }
})
