
import Vue from 'vue'
import { mapGetters } from 'vuex'
import loadLine from './../loadLine/index.vue'

export default Vue.extend({
  name: 'kyc-basic',
  data () {
    return {
      openedPanel: 1
    }
  },
  computed: {
    ...mapGetters('user', ['getUserData'])
  },
  components: { loadLine }
})
