
import Vue, { PropType } from 'vue'
export interface HeaderColors {
  darkest: string;
  mediumDark: string;
  mediumLight: string;
  lightest: string;
}

export default Vue.extend({
  name: 'HeaderBackground',
  props: {
    colors: {
      type: Object as PropType<HeaderColors>,
      required: true
    }
  },
  computed: {
    colorsStyle (): Record<string, string> {
      return {
        '--hbg-darkest': this.colors.darkest,
        '--hbg-medium-dark': this.colors.mediumDark,
        '--hbg-medium-light': this.colors.mediumLight,
        '--hbg-lightest': this.colors.lightest
      }
    }
  }
})
