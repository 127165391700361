export enum AccessStatus {
  work = 'work',
  repeat = 'repeat'
}

export enum KycStatus {
  loading = 'loading',
  failed = 'failed',
  failedDouble = 'failed-double',
  conflict = 'conflict'
}

export interface IUnifiedAcc {
  id: string;
  fullName: string;
  email: string;
}

export enum NicknameErrors {
  FORBIDDEN_WORD = 'FORBIDDEN_WORD', // Запрещенное слово
  UNIQUENESS_ERROR ='UNIQUENESS_ERROR', // Никнейм должен быть уникальным
  INVALID_LENGTH ='INVALID_LENGTH', // Длиной от 5 до 20 символов
  INVALID_SYMBOLS ='INVALID_SYMBOLS', // Не должен содержать символов кроме: латинские буквы, цифры, точка, нижнее подчёркивание
  INVALID_UPPER_CASE ='INVALID_UPPER_CASE' // В никнейме не может быть заглавных букв. Все буквы вводятся в инпут только строчными
}
