
import Vue from 'vue'
import layouts from '@/layouts/index.vue'
import { mapGetters } from 'vuex'
import { UserDataInterface } from '@/store/modules/user/types'
import Chatra from '@chatra/chatra'

function setChatraUserData (userData: UserDataInterface): void {
  Chatra('setIntegrationData', {
    name: userData.last_name + ' ' + userData.first_name + ' ' + userData.patronymic,
    email: userData.email,
    phone: userData.phone,
    notes: `Паспорт. ${document.title}`
  })
}

// const setBitrixChatData = (userData: UserDataInterface) => {
//   const dataString = {
//     type: {
//       name: userData.first_name,
//       'last-name': userData.last_name,
//       phone: userData.phone,
//       email: userData.email
//     },
//     name: {
//       CONTACT_NAME: userData.first_name,
//       CONTACT_LAST_NAME: userData.last_name,
//       CONTACT_PHONE: userData.phone,
//       CONTACT_EMAIL: userData.email
//     }
//   }
//   localStorage.setItem('bitrix-chat-data', JSON.stringify(dataString))
// }

export default Vue.extend({
  name: 'App',
  computed: {
    ...mapGetters('user', ['getUserData', 'getIsAuthenticated'])
  },
  watch: {
    getIsAuthenticated (v) {
      const vm = this as any
      if (v) {
        setChatraUserData(vm.getUserData)
        // setBitrixChatData(vm.getUserData)
      }
    }
  },
  beforeCreate (): void {
    const setTrueVH = () => {
      const trueVH = window.innerHeight / 100
      document.body.style.setProperty('--tVH', trueVH + 'px')
    }
    setTrueVH()
    window.addEventListener('resize', setTrueVH)
  },
  mounted (): void {
    const vm = this as any
    if (vm.getIsAuthenticated) {
      setChatraUserData(vm.getUserData)
      // setBitrixChatData(vm.getUserData)
    }
  },
  async created (): Promise<void> {
    const vm = this as any
    await vm.$store.dispatch('user/loadUser')
    this.$store.dispatch('notifications/loadAll')
  },
  components: {
    layouts
  }
})
