import '@/styles/main.scss'
import polyfils from '@/utils/polyfils'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue'
import services from '@/services/index'
import Cookies from 'js-cookie'
import vueHelpers from '@/vue-utils/index'
import filters from '@/vue-utils/filters'
import translate from '@/assets/rawData/translate.json'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import { isTrustedEnvironment, getServiceConfigProperty } from '@/constants/services'
import { resolveSearchParams } from '@/vue-utils/search-params'
import Chatra from '@chatra/chatra'
// @ts-ignore
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import * as Sentry from '@sentry/vue'

import eventDependentFocus from '@whitelist/wl-old-library/plugins/event-dependent-focus'
import VuetifyInputmask from '@whitelist/wl-old-library/plugins/vuetify-inputmask'
import Icon from '@whitelist/wl-old-library/components/global/Icon/index.vue'
import Numberbox from '@whitelist/wl-old-library/components/global/number-box/index.vue'
import serverErrors from '@whitelist/wl-old-library/components/global/serverErrors/index.vue'
import customAlert from '@whitelist/wl-old-library/components/global/alert/index.vue'
import customSelect from '@whitelist/wl-old-library/components/global/customSelect/index.vue'
import VueScrollComponent from '@whitelist/wl-old-library/components/global/scrollComponent/index.vue'
import popup from '@whitelist/wl-old-library/components/global/popup/index.vue'

Vue.use(VueI18n)
Vue.use(eventDependentFocus)
Vue.use(VuetifyInputmask)

Sentry.init({
  Vue,
  dsn: 'https://1081778ba1df87cbd3bf8377390f2659@o4505993391570944.ingest.sentry.io/4505998317060096',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const additionalPopupsRequires = require.context('./../src/components/', true, /\.popup-chunk\.vue$/i)
const additionalPopups = additionalPopupsRequires.keys().reduce((acc: any, curr: string) => {
  const parsed = require('./../src/components/' + curr.substring(2))
  acc[parsed.default.extendOptions.name] = parsed.default
  return acc
}, {})

const Popup: any = popup

Popup.options.components = {
  ...Popup.options.components,
  ...additionalPopups
}

Vue.component('popup', Popup)
// Vue.component('vue-tel-input-vuetify', VueTelInputVuetify)
Vue.component('Icon', Icon)
Vue.component('NumberBox', Numberbox)
Vue.component('serverErrors', serverErrors)
Vue.component('custom-alert', customAlert)
Vue.component('vue-scroll-component', VueScrollComponent)
Vue.component('custom-select', customSelect)
Vue.component('vue-phone-number-input', VuePhoneNumberInput)

Vue.config.productionTip = false
for (const method of Object.values(polyfils)) {
  method()
}

Vue.prototype.$services = services
Vue.prototype.$cookies = Cookies

Vue.prototype.getColor = (color: string) => vueHelpers.getColor(color, vuetify)
Vue.prototype.getSwitch = vueHelpers.getSwitch

for (const [name, f] of Object.entries(filters)) {
  Vue.filter(name, f)
}

Vue.mixin({
  computed: {
    miw () {
      const vm = this as any
      return (width: number): boolean => vm.$root.windowWidth >= width
    },
    maw () {
      const vm = this as any
      return (width: number): boolean => vm.$root.windowWidth < width
    },
    mih () {
      const vm = this as any
      return (height: number): boolean => vm.$root.windowHeight >= height
    },
    mah () {
      const vm = this as any
      return (height: number): boolean => vm.$root.windowHeight < height
    }
  }
})

Vue.prototype.isTrustedEnvironment = isTrustedEnvironment

Vue.prototype.getServiceConfigProperty = getServiceConfigProperty

declare global {
  interface Window {
    ym: any,
    _tmr: Record<string, unknown>[];
  }
}

Chatra('init', {
  ID: 'sm8Fa43pBrkMWnTEA'
})

const i18n = new VueI18n({
  locale: 'ru', // set locale
  fallbackLocale: 'ru', // set fallback locale
  messages: translate,
  pluralizationRules: {
    /**
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    ru: function (choice, choicesLength) {
      // this === VueI18n экземпляра, так что свойство locale также существует здесь

      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return (choicesLength < 4) ? 2 : 3
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  vuetify: vuetify,
  data: () => ({
    windowHeight: 0,
    windowWidth: 0
  }),
  render: h => h(App),
  created (): void {
    const vm = this as any

    resolveSearchParams(vm)

    setTimeout(() => {
      axios.get('https://ipinfo.io').then(response => {
        let dial = response.data.country.toLowerCase()
        vm.$store.commit('setUserCountryISO', dial)
        switch (dial) {
          case 'ru':
            dial = 'ru'
            break
          case 'ua':
            dial = 'ru'
            break
          case 'by':
            dial = 'ru'
            break
          case 'kz':
            dial = 'ru'
            break
          default:
            // Дропнуть на 'en' когда будет полный проф перевод
            dial = 'ru'
        }
        i18n.locale = dial
      })
    }, 100)
    vm.windowWidth = window.innerWidth
    vm.windowHeight = window.innerHeight
    window.addEventListener('resize', () => {
      vm.windowWidth = window.innerWidth
      vm.windowHeight = window.innerHeight
    })
  }
}).$mount('#app')
