import axios, { AxiosError } from 'axios'
import SERVICE_CONSTANTS from '@/constants/services'
import Router from '@/router/index'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import * as Sentry from '@sentry/vue'

enum Statuses {
  unauthorized = 401
}

let refreshPromise: Promise<void> | null = null

const checkServerError = (error: any): boolean => error.response.status >= 500 && error.response.status <= 526

const a = axios.create({
  baseURL: SERVICE_CONSTANTS.BASE_URL,
  withCredentials: true
})

const resetJWTR = (): void => {
  Cookies.remove('jwtr')
}

async function tryRefresh (): Promise<void> {
  const token = Cookies.get('jwtr')
  if (token) {
    const refreshResponse = await a.post(SERVICE_CONSTANTS.BASE_URL + '/token/refresh/', {
      refresh: token
    })
    if (refreshResponse.status === undefined) {
      Sentry.captureException(new Error('refresh response status error'), { extra: { response: refreshResponse } })
    }
    if (Boolean(refreshResponse.status) && refreshResponse.status === 200 && refreshResponse.data.refresh) {
      const refresh = refreshResponse.data.refresh
      const location = window.location
      const host = location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0]
      Cookies.set('jwtr', refresh, {
        expires: moment.unix((jwtDecode(refresh) as any).exp).toDate(),
        domain: '.' + host
      })
      return Promise.resolve()
    } else resetJWTR()
  } else resetJWTR()

  return Promise.reject(new Error())
}

a.interceptors.response.use((response) => {
  return Promise.resolve(response)
}, async (error) => {
  if (error.response.status === Statuses.unauthorized) {
    if (error.config.url.includes('/token/refresh/')) {
      return Promise.reject(error)
    }
    if (refreshPromise === null) {
      refreshPromise = tryRefresh().finally(() => { refreshPromise = null })
    }
    try {
      await refreshPromise
      return a.request(error.config)
    } catch (e) {
      return Promise.reject(error)
    }
  } else if (checkServerError(error)) {
    Router.replace({ name: 'NamedErrorPage', params: { code: error.response.status } })
  } else return Promise.reject(error)
})

// @ts-ignore
export default a
