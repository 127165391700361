export enum LevelName {
  basic = 'basic',
  extended = 'extended',
  advanced = 'advanced'
}

export enum LevelStatuses {
  empty = '',
  start = 'start',
  inProgress = 'in-progress',
  success = 'success',
  rejected = 'rejected'
}

export enum AccessRepairStatuses {
  work = 'work',
  start = 'start',
  repeat = 'repeat'
}

export enum LinkStatus {
  request = 'request',
  process = 'process'
}
