
import Vue from 'vue'

export default Vue.extend({
  name: 'footerComponent',
  data: () => ({
    baseUrl: window.location.host
  }),
  computed: {
    footerCompanyHref () {
      if (this.getServiceConfigProperty('companyAbrv') === 'Apex') return 'https://passport.apex-prop.company/'
      return 'https://trade.whitelist.capital/'
    }
  }
})
