
import Vue from 'vue'
import SERVICES from '@/constants/services'
export default Vue.extend({
  name: 'agreeDataIsProof',
  data: () => ({
    loading: false
  }),
  methods: {
    async getKycLink () {
      const kycLinkResponse: { data: { url: string, errors: string[] }, success: boolean } = await this.$services.authorization.user.kycLink()
      if (kycLinkResponse.success) return kycLinkResponse.data.url
      else {
        this.$store.dispatch('alertsBus/openAlert', {
          title: this.$t('alerts.titles.error'),
          type: 'error',
          autoDelete: true,
          content: kycLinkResponse.data.errors.join('\n')
        })
        return null
      }
    },
    async proceedVerifyHandler () {
      this.loading = true
      const link = await this.getKycLink()
      this.loading = false
      this.$store.commit('popupModule/setClosed')
      if (!link) return
      window.open(link, '_blank')
    }
  },
  computed: {
    services () {
      return SERVICES
    }
  }
})
