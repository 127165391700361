export enum VerificationStatuses {
  notStarted = 'notStarted',
  process = 'process',
  cancelled = 'cancelled',
  accepted = 'accepted',
  wait = 'wait',
  declined = 'declined',
  addressConflict = 'addressConflict',
  double = 'double'
}

export interface ILevel {
  status: VerificationStatuses,
  reason: null | string
}

export interface IKycLinkDataResponse {
  basic: ILevel,
  extended: ILevel,
  advanced: ILevel,
}
