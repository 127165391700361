
import Vue from 'vue'
export default Vue.extend({
  name: 'goToAnotherAcc',
  data: () => ({}),
  methods: {
    goToAnotherAccHandler () {
      this.$store.commit('popupModule/setClosed')
      const { href } = this.$router.resolve({
        name: 'SignInKYC'
      })
      window.open(href, '_blank')
    }
  }
})
