
import Vue from 'vue'
import LegalInfoPanel from '../../expansion-panel/index.vue'
import LegalInfoPanelContent from '../../expansion-panel-content/index.vue'
import { mapGetters } from 'vuex'
import { LegalInformationTypes } from '@/components/pages/whitelistID/components/legalInfo/components/panels/types'

export default Vue.extend({
  name: 'legalInfoPanelWarning',
  components: { LegalInfoPanel, LegalInfoPanelContent },
  props: {
    section: {
      type: Object,
      required: true
    },
    sectionHeight: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('user', ['getUserData']),
    sectionHaveChanges () {
      const dateNow = Date.now()
      return this.section.documents.some((document: any) => {
        const releasedDate = Date.parse(document.releasedDate)
        const twoWeeks = 1000 * 60 * 60 * 24 * 14
        return dateNow - twoWeeks < releasedDate
      })
    }
  },
  methods: {
    onChange () {
      this.$emit('onChange')
    }
  }
})
