
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import SERVICES_CONSTANTS from '@/constants/services'
import * as Sentry from '@sentry/vue'

let authenticationSocket = null as null | WebSocket

export default Vue.extend({
  name: 'AuthenticationPage',
  data: () => ({
    state: 'loading',
    app: 'Whitelist Terminal',
    isLoading: true,
    timer: 20,
    timeoutId: null,
    connectionTimer: null
  }),
  computed: {
    ...mapGetters('user', ['getUserData', 'getIsAuthenticated', 'getIsLoaded']),
    title (): string {
      switch (this.state) {
        case 'success': return this.$t('authentication.title.success', { app: this.app })
        case 'invalidToken': return this.$t('authentication.title.invalidToken')
        case 'notAlfaUser': return this.$t('authentication.title.notAlfaUser')
        case 'error': return this.$t('authentication.title.error', { app: this.app })
        case 'linkIsExpired': return this.$t('authentication.title.linkIsExpired')
        default: return this.$t('authentication.title.confirm', { app: this.app })
      }
    },
    name (): string {
      return `${this.getUserData.first_name} ${this.getUserData.last_name}`
    }
  },
  beforeDestroy () {
    this.stopTimer()
    this.stopConnectionTimer()
  },
  methods: {
    stopTimer () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
    },
    startTimer () {
      this.timeoutId = setTimeout(() => {
        this.state = 'linkIsExpired'
      }, 5 * 60 * 1000)
    },
    startConnectionTimer () {
      this.connectionTimer = setTimeout(() => {
        this.state = 'error'
      }, 10000)
    },
    stopConnectionTimer () {
      if (this.connectionTimer) {
        clearTimeout(this.connectionTimer)
      }
    },
    redirectToTrade (): void {
      this.$store.commit('setAppIsRedirecting', true)
      window.location.href = SERVICES_CONSTANTS.ACCEPTOR_BASE_URL
    },
    closeSocket (): void {
      if (authenticationSocket === null) return
      authenticationSocket.onerror = null
      authenticationSocket.onclose = null
      authenticationSocket.close()
      authenticationSocket = null
    },
    startAuthentication (): void {
      this.startConnectionTimer()
      this.state = 'loading'
      authenticationSocket = new WebSocket(`wss://${document.location.hostname}/ws/auth-frontend`)
      authenticationSocket.onerror = () => {
        this.state = 'error'
        this.stopTimer()
      }
      authenticationSocket.onclose = () => {
        this.state = 'error'
        authenticationSocket = null
        this.stopTimer()
      }
      authenticationSocket.onmessage = (message) => {
        const response = JSON.parse(message.data).payload
        if (response.result === false) {
          this.state = 'error'
          this.closeSocket()
          this.stopTimer()
          this.stopConnectionTimer()
        } else if (response.result === true) {
          this.state = 'success'
          this.closeSocket()
          this.startRedirect()
          this.stopConnectionTimer()
          window.ym(98011557, 'reachGoal', 'Terminal_authorization', { userId: this.getUserData.uuid })
          window.ym(89268028, 'reachGoal', 'Terminal_authorization', { userId: this.getUserData.uuid })
        }
      }
      authenticationSocket.onopen = () => {
        if (authenticationSocket !== null) {
          authenticationSocket.send(JSON.stringify({
            action: 'userAuthApprove',
            messageId: '1',
            payload: {
              clientToken: this.$route.query.clientToken
            }
          }))
        }
      }
    },
    initSocket (): void {
      authenticationSocket = new WebSocket(`wss://${document.location.hostname}/ws/auth-frontend`)
      authenticationSocket.onerror = () => {
        this.state = 'error'
        this.stopTimer()
      }
      authenticationSocket.onclose = () => {
        this.state = 'error'
        authenticationSocket = null
        this.stopTimer()
      }
    },
    startRedirect (): void {
      window.setTimeout(() => {
        if (this.timer === 0) {
          this.redirectToTrade()
        } else {
          this.timer -= 1
          this.startRedirect()
        }
      }, 1000)
    }
  },
  watch: {
    getIsLoaded (v: boolean) {
      if (v && !this.getIsAuthenticated) {
        this.$router.replace({ name: 'SignIn', params: { clientToken: this.$route.query.clientToken } })
      }
    }
  },
  async created () {
    try {
      const response = await this.$services.authorization.authentication.verifyClientToken({ token: this.$route.query.clientToken })
      if (response.success) {
        this.app = response.data.clientName
        switch (response.data.verificationResult) {
          case 'valid': {
            this.state = 'confirm'
            this.startTimer()
            break
          }
          case 'forbidden': {
            this.state = 'notAlfaUser'
            break
          }
          default: {
            this.state = 'invalidToken'
          }
        }
        this.isLoading = false
      } else if (response.status === 400 && response.data.error.includes('Не удалось найти запрос на авторизацию с токеном')) {
        this.state = 'invalidToken'
        this.isLoading = false
      } else if (response.status === 401) {
        this.$router.replace({ name: 'SignIn', params: { clientToken: this.$route.query.clientToken } })
      } else {
        Sentry.captureException(new Error('clientToken verification error'), { extra: { token: this.$route.query.clientToken, response: response.data } })
      }
    } catch (e) {
      Sentry.captureException(new Error('clientToken verification error'), { extra: { token: this.$route.query.clientToken, response: e } })
    }
  },
  beforeRouteEnter (to, from, next) {
    if (!Cookies.get('jwtr')) {
      // @ts-ignore
      next({ name: 'SignIn', params: { clientToken: to.query.clientToken } })
    } else {
      next()
    }
  }
})
