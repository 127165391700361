
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { KycStatus, IUnifiedAcc } from '../types'

export default Vue.extend({
  name: 'kyc-status-card',
  props: {
    kycType: {
      type: String,
      required: true,
      default: KycStatus.loading
    },
    reason: {
      type: String,
      required: false,
      default: ''
    },
    unifiedAcc: {
      type: Array,
      required: false,
      default: () => [] as IUnifiedAcc[]
    }
  },
  data () {
    return {
      unifiedUserId: '',
      acceptLoading: false
    }
  },
  methods: {
    async handleClickAccept () {
      this.acceptLoading = true
      const setKycLinkResponse: { success: boolean, data: any, status: number } = await this.$services.authorization.user.sendAccountConflict({ payload: { userId: this.unifiedUserId } })
      this.acceptLoading = false

      if (setKycLinkResponse.success || setKycLinkResponse.status === 200) {
        const content = this.unifiedAcc.map((acc: { email: string }) => acc.email).join(',\n')
        this.$store.dispatch('alertsBus/openAlert', {
          title: this.$t('alerts.titles.success'),
          type: 'success',
          autoDelete: true,
          content: this.$t('alerts.content.accContent', { content })
        })
        this.$emit('closeStatusCard')
      } else {
        this.$store.dispatch('alertsBus/openAlert', {
          title: this.$t('alerts.titles.error'),
          type: 'error',
          autoDelete: true,
          content: 'Операция не удалась'
        })
      }
    }
  },
  computed: {
    ...mapGetters('popupModule', ['getPopupIsOpened']),
    ...mapGetters('user', ['getUserData'])
  }
})
