export function getUnparsedURIParam (param: string): any {
  const par: any = {}
  window.location.search.slice(1).split('&').forEach(elm => {
    if (elm === '') return
    const spl: any = elm.split('=')
    par[spl[0]] = (spl.length >= 2 ? spl[1] : true)
  })
  if (par[param]) {
    return decodeURIComponent(par[param])
  }
}

const projectPath = (url: URL | string): string => {
  try {
    const parsedUrl = new URL(typeof url === 'string' ? url : url.toString())
    const isBybit = parsedUrl.pathname.startsWith('/bybit/')
    if (isBybit) return '/bybit/'
    const isBinance = parsedUrl.pathname.startsWith('/binance/')
    if (isBinance) return '/binance/'
    return ''
  } catch (error) {
    return ''
  }
}

export const resolveSearchParams = (vm: Vue): void => {
  const currentUrl = new URL(window.location.href)
  const acceptor = getUnparsedURIParam('acceptor')
  if (acceptor) {
    const acceptorURL = new URL(acceptor)
    localStorage.setItem('acceptor', acceptorURL.origin + projectPath(acceptorURL))
    sessionStorage.setItem('acceptor', acceptorURL.href)
    currentUrl.searchParams.delete('acceptor')
  }

  const origin = currentUrl.origin
  vm.$router.replace(currentUrl.href.slice(origin.length))
}
