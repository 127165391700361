import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from '@/router/routes'
import Store from '@/store/index'
import Cookies from 'js-cookie'
import Chatra from '@chatra/chatra'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const notFreePagesListNames = ['whitelistIDUserAccount', 'whitelistIDUserSafety', 'SignInKYC', 'SignInSecondFactorKYC']

router.beforeEach((to, from, next) => {
  if (notFreePagesListNames.includes(to.name + '')) {
    if (!Cookies.get('jwtr')) {
      router.replace({ name: 'SignIn' })
    }
  }
  if (Store.getters.getMessageInstanceState.opened) {
    Store.commit('setMessageInstanceState', {
      opened: false,
      title: '',
      description: ''
    })
  }
  next()
})

router.afterEach((to, from) => {
  if (to.meta?.title) {
    document.title = to.meta.title
  }
  const toHref = new URL(to.fullPath, window.location.origin).toString()
  const fromHref = new URL(from.fullPath, window.location.origin).toString()
  /* window.ym(89268028, 'hit', toHref, {
    title: document.title,
    referer: fromHref
  })
  window.ym(93312770, 'hit', toHref, {
    title: document.title,
    referer: fromHref
  }) */
  Chatra('updateIntegrationData', {
    notes: `Паспорт. ${document.title}`
  })
})

export default router
