
import Vue from 'vue'
import { mapGetters } from 'vuex'
import basic from './components/basic/index.vue'
import extended from './components/extended/index.vue'
import advanced from './components/advanced/index.vue'
import SERVICES from '@/constants/services'
import { IKycLinkDataResponse, VerificationStatuses } from './../../types'

const defaultKycData: IKycLinkDataResponse = {
  basic: {
    status: VerificationStatuses.accepted,
    reason: null
  },
  extended: {
    status: VerificationStatuses.notStarted,
    reason: null
  },
  advanced: {
    status: VerificationStatuses.notStarted,
    reason: null
  }
}

export default Vue.extend({
  name: 'kyc',
  props: {
    kycData: {
      type: Object,
      required: true,
      default: () => defaultKycData
    },
    kycAccordionStatus: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  watch: {
    kycAccordionStatus (value: boolean) {
      if (value) this.openedPanel = 0
    },
    openedPanel (value: number) {
      if (!value) this.$emit('hideKycPanel')
    }
  },
  data () {
    return {
      openedPanel: null as number | null,
      selectedNecessityVerifyElementId: 'legality',
      showNecessityVerify: false,
      verificationInitialized: false,
      verifyNecessityElements: [
        { title: 'Законность', id: 'legality' },
        { title: 'Безопасность', id: 'safety' },
        { title: 'Конфиденциальность', id: 'privacy' },
        { title: 'Защита аккаунта', id: 'accountDefender' },
        { title: 'Надежность', id: 'reliability' }
      ]
    }
  },
  created () {
    const kycFAQURL = new URL('/kyc-faq', SERVICES.PASSPORT_URL)

    const acceptor = sessionStorage.getItem('acceptor')
    if (acceptor !== null) {
      kycFAQURL.searchParams.set('acceptor', acceptor)
    }

    this.kycFAQLink = kycFAQURL.toString()
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    const verificationShow = urlParams.get('verification-show') === 'true'
    if (verificationShow) {
      this.openedPanel = 0
    }
  },
  methods: {},
  computed: {
    ...mapGetters('popupModule', ['getPopupIsOpened']),
    ...mapGetters('user', ['getUserData']),
    showMainButton () {
      let levelMatch = false
      const currentLevel = this.getUserData.verificationRights.levelName
      const matchLevelToShowButton = [VerificationStatuses.notStarted, VerificationStatuses.process, VerificationStatuses.cancelled, VerificationStatuses.declined, VerificationStatuses.double]
      if (currentLevel === 'basic') levelMatch = matchLevelToShowButton.includes(this.kycData.extended.status)
      if (currentLevel === 'extended') levelMatch = matchLevelToShowButton.includes(this.kycData.advanced.status)
      return this.openedPanel !== 0 && levelMatch
    }
  },
  components: { basic, extended, advanced }
})
