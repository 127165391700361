var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"kyc"},[_c('v-row',[_c('v-col',[_c('v-expansion-panels',{model:{value:(_vm.openedPanel),callback:function ($$v) {_vm.openedPanel=$$v},expression:"openedPanel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"account_title"},[_c('h2',{staticClass:"kyc-card-title"},[_vm._v(_vm._s(_vm.$t('main.accountVerify.title')))]),(_vm.showMainButton)?_c('div',[_c('v-btn',{staticClass:"btn btn--accent btn-thin mr-8",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('proceedVerification')}}},[_vm._v(_vm._s(_vm.$t('main.accountVerify.proceedVerification'))+" ")])],1):_vm._e()]),_c('span',{staticClass:"account_subtitle"},[_vm._v(_vm._s(_vm.$t('main.accountVerify.subtitle')))])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"wrap",class:{
              'my-6': _vm.miw(460),
              'mt-3 mb-5': _vm.maw(460)
            }},[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('v-btn',{staticClass:"btn btn-smallest btn-smallest-text btn-flat currency_reset-btn mt-auto account_change-button px-0",class:{
                    'mb-1': _vm.miw(460),
                    'mb-3': _vm.maw(460)
                  },on:{"click":function($event){_vm.showNecessityVerify=!_vm.showNecessityVerify}}},[_vm._v(_vm._s(_vm.$t('main.accountVerify.whyVerify')))]),_c('v-btn',{staticClass:"btn btn-smallest btn-smallest-text btn-flat currency_reset-btn mt-auto account_change-button px-0",attrs:{"href":_vm.kycFAQLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('main.accountVerify.faq'))+" ")])],1),(_vm.showNecessityVerify)?_c('div',{staticClass:"verify-explanation mt-4"},[_c('v-row',[_c('v-col',{staticClass:"necessity-verify-titles",attrs:{"cols":_vm.maw(768) ? 12 : 4}},_vm._l((_vm.verifyNecessityElements),function(element){return _c('div',{key:element.id,staticClass:"necessity-verify-titles__title mb-2",class:{'necessity-verify-titles__title-active': _vm.selectedNecessityVerifyElementId === element.id},on:{"click":function($event){_vm.selectedNecessityVerifyElementId = element.id}}},[_c('v-img',{staticClass:"historyTablesBody_status-icon mr-2",attrs:{"lazy-src":require('@/assets/icons/svg/success.svg'),"src":require('@/assets/icons/svg/success.svg'),"max-width":"12px","max-height":"16px","width":"100%","height":"100%"}}),_c('span',[_vm._v(_vm._s(_vm.$t(`main.accountVerify.necessity.titles.${element.id}`)))])],1)}),0),_c('v-col',{attrs:{"cols":_vm.maw(768) ? 12 : 8}},[_c('div',{staticClass:"necessity-verify-content"},_vm._l((_vm.verifyNecessityElements),function(element){return _c('div',{key:element.id},[(_vm.selectedNecessityVerifyElementId === element.id)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t(`main.accountVerify.necessity.${element.id}`))}}):_vm._e()])}),0)])],1)],1):_vm._e()]),_c('basic'),_c('extended',{attrs:{"levelData":_vm.kycData.extended},on:{"proceedVerification":function($event){return _vm.$emit('proceedVerification')}}}),_c('advanced',{attrs:{"levelData":_vm.kycData.advanced},on:{"proceedVerification":function($event){return _vm.$emit('proceedVerification')}}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }