
import Vue from 'vue'
import darkLayout from './dark-layout.vue'
import whitelistIDLayout from './whietlst-id.vue'
import headerComponent from '@/components/global/layout/header/index.vue'
import footerComponent from '@/components/global/layout/footer/index.vue'
import instructionsMessage from '@/components/global/instructionsMessage/index.vue'
import CookiesBanner from '@whitelist/wl-old-library/components/global/cookies/index.vue'
import SERVICES from '@/constants/services'

import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'layoutComponent',
  data: () => ({
    navList: [
      {
        name: 'Sign In',
        to: { name: 'SignIn' }
      },
      {
        name: 'Sign Up',
        to: { name: 'SignUp' }
      }
    ]
  }),
  computed: {
    ...mapGetters(['getAppIsRedirecting']),
    ...mapGetters('alertsBus', ['getAlertsList']),
    isDarkLayout (): boolean {
      const vm = this as any
      const whitelistIDLayouts = ['whitelistIDUserAccount', 'whitelistIDUserSafety', 'whitelistIDAbout', 'whitelistIDLegalInformation']
      return !whitelistIDLayouts.includes(vm.$route.name)
    }
  },
  methods: {
    logout (): void {
      (this as any).$services.authorization.signin.logout(null)
    }
  },
  created () {
    this.cookiesRulesHref = SERVICES.PASSPORT_URL + '/id/legal-information'
  },
  components: {
    'whitelist-id-layout': whitelistIDLayout,
    darkLayout,
    headerComponent,
    footerComponent,
    instructionsMessage,
    CookiesBanner
  }
})
